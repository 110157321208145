// 
// card.scss
//

.card {
    margin-bottom: $grid-gutter-width;

    .header-title {
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-size: $font-size-base;
        margin-top: 0;
    }
    .card-drop {
        font-size: 20px;
        line-height: 0;
        color: inherit;
    }
    .card-widgets {
        float: right;
        height: 16px;
        > a {
            color: inherit;
            font-size: 18px;
            display: inline-block;
            line-height: 1;

            &.collapsed {
                i {
                    &:before {
                        content: "\F415";
                    }
                }
            }
        }
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}


//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: rgba($white, 0.8);

    .card-portlets-loader {
        background-color: $dark;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: - ($grid-gutter-width / 2);
        margin-top: - ($grid-gutter-width / 2);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}