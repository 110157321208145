// 
// badge.scss
//

.badge {
    color: $light;
}

.badge-light {
    color: $gray-800;
}